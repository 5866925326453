



#page-wrap {
  text-align: center;
  overflow: auto;
  top: 45%;
  position: relative;
}

.menu-item {
  color: #6a6a6a
}

.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: black !important;
  transition: color 0.2s;
}

.bm-item:hover {
  color: rgb(205, 205, 205);
}

.bm-burger-button {
  position: fixed;
  width: 27px;
  height: 22px;
  right: 20px;
  top: 27px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: black;
  opacity: 50%

}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: white;
  width: 100%;
  position: absolute;
  top: -10;
  left: 0;
  padding: 2.5em 1.5em 0;
  font-size: 35px;
}

.bm-menu-wrap {
  position: absolute;
  top: 0;
  left: 0;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: black;
}

/* Wrapper for item list */
.bm-item-list {
  color: #bdc3c7;
}

/* Styling of overlay */
.bm-overlay {
  background: white;
  position: absolute;
  top: 0;
  left: 0;
}
